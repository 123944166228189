@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3 {
  --font-weight: 600;

  overflow: hidden;
  width: fit-content;
  font-family: var(--font-family-secondary);
  font-size: clamp(2rem, 2rem + 1vi, 56px);

  @include media(mobile-m) {
    line-height: 1.2;
  }
}

a,
button {
  --font-weight: 400;

  font-family: var(--font-family-primary);
}

h2 {
  font-size: clamp(40px, 2rem + 2vi, 56px);
  text-transform: capitalize;
  color: var(--color-blue);
  font-weight: var(--fw-800);
  line-height: 1.2;
}

h3 {
  font-size: clamp(32px, 2rem + 1vi, 40px);
  text-transform: capitalize;
  color: var(--color-blue);
  font-weight: var(--fw-700);
}

p {
  font-size: 20px;
  line-height: 1.4;
}