/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
  --content-width: 1180px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Open Sans", sans-serif;

  // font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-700: 700;
  --fw-800: 800;

  // color
  --color-white: #fff;
  --color-black: #444444;
  --color-blue: #476EB9;
  --color-dark-blue: #345189;
  --color-gray-bg: #F6F7F8;
  --color-dark-900: #1b1b1b;
  --color-gray-400: #f6f7f8;
  --color-gray-600: #a4a4a4;
  --color-gray-650: #a2a2a2;
  --color-gray-700: #4b4b4b;
  --color-tile-800: #0c3c43;

  //  radius
  --radius-main: 12px;
  --radius-double: 24px;

  // shadow
  --shadow-primary: 0px 8px 8px -4px #1118270A;
  --shadow-secondary: 0px 20px 24px -4px #1118271A;
}