@use "../../utils/functions/" as*;
@use "../../utils/mixins/" as*;

.approach {
  $root: &;
  padding-top: rem(40);
  background: url('../images/about/approach.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  &__header,
  &__item {
    background-color: var(--color-white);
    border-radius: calc(var(--radius-main) * 2);
  }

  &__header {
    padding: 40px;

    h2,
    h3 {
      margin-bottom: 16px;
    }

    h3 {
      font-size: 32px;
      line-height: 44px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
  }

  &__first {
    grid-template-columns: repeat(4, 1fr);
  }

  &__item {
    padding: 24px;

    &-title {
      font-size: 24px;
      font-weight: var(--fw-700);
      line-height: 33px;
      color: var(--color-blue);
      margin-bottom: 12px;
    }
  }

  @include media(tablet) {
    &__first {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include media(mobile-l) {

    &__item {
      padding: 24px;
    }
  }

  @include media(mobile-m) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}