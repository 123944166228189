@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.services {
  padding-top: rem(80);
  background-color: var(--color-gray-400);

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 2);
    padding: 0;
    height: 100%;
    background-color: var(--color-white);

    &-inner {
      padding: 32px;
      border-radius: calc(var(--radius-main) * 2);
      background: var(--color-white);
      top: (calc(var(--radius-main) * -2));
      position: relative;
    }
  }

  h2 {
    margin-bottom: rem(40);
  }

  h3 {
    margin-bottom: 14px;
    font-size: 32px;
    text-transform: capitalize;
  }

  &__description {
    padding: 40px;
    background: var(--color-white);
    border-radius: calc(var(--radius-main) * 2);
    margin-bottom: rem(40);

    &-inner {
      max-width: 740px;
    }
  }

  .btn {
    margin: rem(60) auto 0;
  }

  @include media(tablet) {
    padding: rem(60) 0;

    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      &:last-child {
        grid-column: 1 / span 2;
      }
    }
    
    .btn {
      margin-top: rem(40);
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }
  }

}