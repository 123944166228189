@use "../../utils/functions/" as*;
@use "../../utils/mixins/" as*;

.article {
    padding-top: rem(80);

    &__title {
        font-size: clamp(2rem, 2rem + 1vi, 56px);
        text-transform: capitalize;
        color: var(--color-blue);
        font-weight: var(--fw-800);
        line-height: 1.2;
    }

    &__inner {
        margin-top: 40px;
        padding: 40px;
        background-color: var(--color-gray-bg);
        border-radius: var(--radius-double);

        &>:last-child {
            margin-bottom: 0;
        }

        >p {
            margin-bottom: 40px;

            &.ol {
                color: var(--color-blue);
                font-size: 32px;
                font-weight: var(--fw-700);
                margin-bottom: 12px;
            }

            &.title__black {
                font-size: 32px;
                font-weight: 800;
                margin-bottom: 24px;
            }
        }

        img {
            border-radius: var(--radius-double);
            margin-bottom: 40px;
        }

        .list-disc {
            margin-bottom: 40px;

            li {
                list-style-type: disc;
            }
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 3fr);
        gap: 20px;
        margin-bottom: 40px;
    }

    &__item {
        border-radius: calc(var(--radius-main) * 2);
        padding: rem(26);
        background-color: var(--color-white);

        h3 {
            margin-bottom: 12px;
            font-size: 32px;
        }
    }

    &__item {
        list-style: none;

        &:last-child {
            grid-column: 1 / span 2;
        }
    }
    
    @include media(tablet) {
        padding-top: rem(60);
    }

    @include media(mobile-m) {
        padding: rem(40) 0;

        &__title {
            font-size: 40px;
        }

        &__inner {
            padding: 30px;

            p {
                &.title__black {
                    font-size: 24px;
                }
            }
        }

        &__list {
            grid-template-columns: 1fr;
        }

        &__item {
            &:last-child {
                grid-column: auto;
            }
        }
    }
}