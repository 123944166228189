@use "../../utils/functions/" as*;
@use "../../utils/mixins/" as*;

.reviews {
  overflow: hidden;
  padding-top: rem(100);
  max-width: 100%;

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 60px 80px;
  }

  &__item {
    display: flex;
    flex-direction: row;
  }

  &__author {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 32px;

    &-image {
      img {
        border-radius: 100%;
        width: 64px;
        height: 64px;
      }
    }
  }

  &__content {
    flex: 1;
  }

  h3 {
    margin-bottom: 12px;
    font-size: 24px;
    color: var(--color-blue);
  }

  p {
    margin-bottom: 16px;
  }

  svg {
    max-height: 20px;
  }

  @include media(tablet) {
    padding-top: rem(60);

    &__list {
      grid-template-columns: 1fr;
    }
    &__author {
      flex-basis: 150px;
    }
  }

  @include media(mobile-m) {
    &__item {
      flex-direction: column;
    }
    &__author {
      flex-basis: auto;
    }
  }
}