@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.team {
  padding-top: 0;
  padding-bottom: rem(100);
  overflow: hidden;

  h2 {
    margin: 0 auto rem(40);
  }

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__item {
    flex-basis: 216px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    height: auto;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__image {
    margin-bottom: 20px;

    img {
      border-radius: 50%;
      max-width: 150px;
      max-height: 150px;
    }
  }

  &__name {
    h3 {
      margin: 0 auto;
      font-size: 32px;
    }
  
    span {
      display: block;
      text-align: center;
    }
  }
  

  @include media(tablet) {
    padding-bottom: rem(60);

    &__item {
      img {
        max-width: 120px;
      }
    }
  }

  @include media(mobile-l) {
    &__list {
      flex-wrap: wrap;
    }

    &__item {
      margin-right: 0;
      flex-basis: 40%;
      margin-top: 20px;

      &:nth-child(3), &:nth-child(4) {
        margin-top: 40px;
      }
    }

    &__image {
      margin-bottom: 10px;
    }

  }

  @include media(mobile-m) {
    &__item {
      margin-top: 40px;
      flex-basis: 100%;

      &:first-child {
        margin-top: 20px;
      }
    }

  }
}