@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.quality {
  padding-top: 0;
  
  background-color: var(--color-gray-bg);

  h2 {
    margin-bottom: 20px;
  }

  &__description {
    margin-bottom: 40px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 3fr);
    gap: 20px
  }

  &__item {
    border-radius: calc(var(--radius-main) * 2);
    padding: rem(26);
    background-color: var(--color-white);

    h3 {
      margin-bottom: 12px;
      font-size: 32px;
    }
  }

  @include media(mobile-l) {
    &__item {
      padding: 24px;
    }
  }

  @include media(mobile-m) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}