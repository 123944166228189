@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 2);
    min-height: 584px;
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__content {
    z-index: 1;
    padding: 0 rem(80);
    width: 100%;
    max-width: 830px;
    color: var(--color-white);
  }

  h1 {
    margin-bottom: rem(32);
    font-size: clamp(40px, 2rem + 2vi, 56px);
    font-weight: var(--fw-800);
    line-height: 1.2;
  }

  p {
    font-size: 24px;
  }

  &--home {
    padding-top: rem(80);

    p {
      margin-bottom: rem(72);
    }
  }

  &--services {
    padding-top: rem(80);

    #{$root}__inner {
      background-position: 100%;
    }
  }

  &--contact {
    p {
      margin-bottom: 0;
    }
  }

  &--articles {
    padding-top: rem(80);
    padding-bottom: 0;

    h1 {
      margin-bottom: rem(40);
      text-transform: capitalize;
      color: var(--color-blue);
      font-weight: var(--fw-800);
      line-height: 1.2;
    }

    #{$root}__inner {
      min-height: 514px;
      background-color: var(--color-white);
      position: relative;
    }

    #{$root}__content {
      max-width: calc(500px + rem(60)*2);
      color: var(--color-black);

      h2 {
        margin-bottom: 24px;
        font-size: 32px;
        font-weight: var(--fw-700);
        line-height: 40px;
        color: var(--color-black);
      }

      p {
        margin-bottom: 40px;
        font-size: 20px;
      }
    }
  }

  &--portfolio {
    padding: rem(80) 0;
  }

  @include media(tablet) {
    padding-top: 0;

    &--services {
      margin: 0;
      padding-bottom: rem(60);
    }

    &--articles {
      padding-top: rem(30);

      #{$root}__content {
        max-width: 400px;
      }
    }

    &__inner {
      min-height: 442px;
    }

    &__content {
      padding: 40px 30px;
    }

    &--portfolio {
      padding-bottom: rem(60);
    }
  }

  @include media(mobile-l) {
    &--articles {
      #{$root}__inner {
        min-height: 300px;
      }

      #{$root}__content {
        max-width: 100%;
      }
    }
  }

  @include media(mobile-m) {
    margin: 0 -16px;

    &--portfolio {
      #{$root}__inner {
        background-position: 80%;
      }
    }

    &__inner {
      border-radius: 0;
    }

    .btn {
      max-width: none;
    }
  }
}