@use "../utils/functions/" as*;
@use "../utils/mixins/" as*;

.nav {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;

    @include media(tablet) {
      position: fixed;
      left: 0;
      top: 0;
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding-top: calc(var(--header-height) * 1.5);
      width: 100%;
      height: calc(var(--vh));
      background-color: var(--color-white);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;
    }
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 24px;
    }
  }

  &__link {
    font-weight: var(--fw-700);
    font-size: 16px;
    color: var(--color-blue);

    &.true {
      border-bottom: 2px solid var(--color-blue);
    }
  }

  &__buttons {
    display: flex;
    align-items: center;

    &-menu {
      display: none;
    }
  }

  &__signup {
    text-wrap: nowrap;
    margin-right: 12px;
  }

  @include media(tablet-wide) {
    .logo {
      max-width: 140px;
    }

    &__item {
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }

    &__link {
      font-size: 14px;
    }

    &__buttons {
      margin-right: 40px;

      .btn {
        padding-left: 12px;
        padding-right: 34px;
      }
    }
  }

  @include media(tablet) {
    &__list {
        z-index: -1;
        flex-direction: column;
    }
    
    &__item {
      margin-bottom: 30px;
      
      &:not(:last-of-type) {
        margin-right: 0;
      }
    }
  }

  @include media(mobile-l) {
    &__buttons {
      display: none;
      margin-right: 0;

      &-menu {
        display: flex;
      }

      .btn {
        margin: 0 auto;
      }
    }
  }

  @include media(mobile-m) {}
}