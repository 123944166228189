@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  padding: rem(60) 0;
  color: var(--color-white);
  background-color: var(--color-blue);

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.nav__main {
      margin-bottom: 24px;

      .footer__link {
        font-weight: var(--fw-500);
      }
    }
  }

  &__item {
    padding: 8px 24px;
  }

  &__link {
    font-size: 16px;
  }

  &__copyright {
    margin-top: 24px;
    text-align: center;
    
    p {
      font-size: 16px;
    }
  }

  @include media(tablet) {

  }

  @include media(mobile-l) {
    &__nav {
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}