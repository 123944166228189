@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap";
:root {
  --content-width: 1180px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Open Sans", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-700: 700;
  --fw-800: 800;
  --color-white: #fff;
  --color-black: #444;
  --color-blue: #476eb9;
  --color-dark-blue: #345189;
  --color-gray-bg: #f6f7f8;
  --color-dark-900: #1b1b1b;
  --color-gray-400: #f6f7f8;
  --color-gray-600: #a4a4a4;
  --color-gray-650: #a2a2a2;
  --color-gray-700: #4b4b4b;
  --color-tile-800: #0c3c43;
  --radius-main: 12px;
  --radius-double: 24px;
  --shadow-primary: 0px 8px 8px -4px #1118270a;
  --shadow-secondary: 0px 20px 24px -4px #1118271a;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-black);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: 16px;
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-height: var(--vh);
  min-width: 320px;
}

.page.open {
  overflow: hidden;
}

section {
  padding: 2.5rem 0 6.25rem;
}

@media only screen and (width <= 992px) {
  section {
    padding: 2.5rem 0 3.75rem;
  }
}

section h2 {
  margin-bottom: 2.5rem;
}

.container {
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

#form-request {
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  margin: auto;
  transition-property: opacity, visibility;
  transition-duration: .2s;
  position: fixed;
  inset: 0;
}

#form-request:before {
  content: "";
  background-color: var(--color-black);
  opacity: .3;
  position: absolute;
  inset: 0;
}

#form-request iframe {
  z-index: 1;
  height: calc(var(--vh)  - 60px);
  border: none;
  width: 90%;
  max-width: 980px;
  margin: 30px auto;
  position: absolute;
  left: 0;
  right: 0;
}

#form-request.open {
  opacity: 1;
  visibility: visible;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  background: linear-gradient(90deg, #0000007a 53.89%, #0000 74.96%);
  position: absolute;
  inset: 0;
}

.bg-gray {
  background-color: var(--color-gray-bg);
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  width: 100%;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  --font-weight: 600;
  font-family: var(--font-family-secondary);
  width: fit-content;
  font-size: clamp(2rem, 2rem + 1vi, 56px);
  overflow: hidden;
}

@media only screen and (width <= 576px) {
  h1, h2, h3 {
    line-height: 1.2;
  }
}

a, button {
  --font-weight: 400;
  font-family: var(--font-family-primary);
}

h2 {
  text-transform: capitalize;
  color: var(--color-blue);
  font-size: clamp(40px, 2rem + 2vi, 56px);
  font-weight: var(--fw-800);
  line-height: 1.2;
}

h3 {
  text-transform: capitalize;
  color: var(--color-blue);
  font-size: clamp(32px, 2rem + 1vi, 40px);
  font-weight: var(--fw-700);
}

p {
  font-size: 20px;
  line-height: 1.4;
}

.header {
  z-index: 10;
  background-color: var(--color-white);
  width: 100%;
  padding: clamp(12px, 1.25rem, 22px) 0;
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-100%);
}

@media only screen and (width <= 576px) {
  .header {
    padding: clamp(12px, 1rem, 16px) 0;
  }
}

.bg-gray .header {
  background-color: var(--color-gray-bg);
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
  display: flex;
}

@media only screen and (width <= 992px) {
  .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.5);
    height: calc(var(--vh));
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  align-items: center;
  display: flex;
}

.nav__item:not(:last-of-type) {
  margin-right: 24px;
}

.nav__link {
  font-weight: var(--fw-700);
  color: var(--color-blue);
  font-size: 16px;
}

.nav__link.true {
  border-bottom: 2px solid var(--color-blue);
}

.nav__buttons {
  align-items: center;
  display: flex;
}

.nav__buttons-menu {
  display: none;
}

.nav__signup {
  text-wrap: nowrap;
  margin-right: 12px;
}

@media only screen and (width <= 1180px) {
  .nav .logo {
    max-width: 140px;
  }

  .nav__item:not(:last-of-type) {
    margin-right: 20px;
  }

  .nav__link {
    font-size: 14px;
  }

  .nav__buttons {
    margin-right: 40px;
  }

  .nav__buttons .btn {
    padding-left: 12px;
    padding-right: 34px;
  }
}

@media only screen and (width <= 992px) {
  .nav__list {
    z-index: -1;
    flex-direction: column;
  }

  .nav__item {
    margin-bottom: 30px;
  }

  .nav__item:not(:last-of-type) {
    margin-right: 0;
  }
}

@media only screen and (width <= 768px) {
  .nav__buttons {
    margin-right: 0;
    display: none;
  }

  .nav__buttons-menu {
    display: flex;
  }

  .nav__buttons .btn {
    margin: 0 auto;
  }
}

[class].btn, button.formsapp-popup-button {
  border-radius: var(--radius-main);
  font-weight: var(--fw-700);
  color: var(--color-white);
  background-color: var(--color-blue);
  justify-content: space-between;
  align-items: center;
  padding: 12px 46px 12px 24px;
  font-size: 16px;
  line-height: 100%;
  transition-property: background-color, color, border-color;
  transition-duration: .2s;
  display: flex;
  position: relative;
}

[class].btn:after, button.formsapp-popup-button:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='18' viewBox='0 0 10 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292894 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L9.70711 8.29289C10.0976 8.68342 10.0976 9.31658 9.70711 9.70711L1.70711 17.7071C1.31658 18.0976 0.683417 18.0976 0.292893 17.7071C-0.0976311 17.3166 -0.0976311 16.6834 0.292893 16.2929L7.58579 9L0.292894 1.70711C-0.0976304 1.31658 -0.0976304 0.683417 0.292894 0.292893Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 15px;
  transition-property: filter;
  transition-duration: .2s;
  position: relative;
  left: 22px;
}

[class].btn:hover, [class].btn:not(.slick-arrow):focus, button.formsapp-popup-button:hover, button.formsapp-popup-button:not(.slick-arrow):focus {
  background-color: var(--color-dark-blue);
}

[class].btn--reverse, button.formsapp-popup-button--reverse {
  border-color: var(--color-dark-900);
  color: var(--color-dark-900);
  background-color: var(--color-white);
}

[class].btn--reverse:after, button.formsapp-popup-button--reverse:after {
  filter: invert();
}

[class].btn--reverse:hover, [class].btn--reverse:focus, button.formsapp-popup-button--reverse:hover, button.formsapp-popup-button--reverse:focus {
  border-color: var(--color-tile-800);
  color: var(--color-white);
  background-color: var(--color-tile-800);
}

[class].btn--reverse:hover:after, [class].btn--reverse:focus:after, button.formsapp-popup-button--reverse:hover:after, button.formsapp-popup-button--reverse:focus:after {
  filter: invert(0);
}

[class].btn--simple, button.formsapp-popup-button--simple {
  border-radius: calc(var(--radius-main)  - 4px);
  text-align: center;
  justify-content: center;
  width: 100%;
  max-width: 172px;
  padding: 12px 0;
  font-size: 18px;
}

[class].btn--simple:after, button.formsapp-popup-button--simple:after {
  display: none;
}

[class].btn--send, button.formsapp-popup-button--send {
  justify-content: center;
  width: 100%;
  min-width: auto;
  max-width: 72px;
  padding: 15px;
}

[class].btn--send:after, button.formsapp-popup-button--send:after {
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.2 2.74567C19.2939 5.53266 18.3699 8.32447 17.2623 11.0393C16.2723 13.4658 15.1224 15.8285 13.9568 18.1748C13.7577 18.5756 13.5621 18.9695 13.3666 19.2521C13.1796 19.5224 12.7512 20.05 12.0131 19.9962C10.9393 19.9179 10.6287 18.752 10.3527 17.9056C9.90168 16.5225 9.49939 15.1302 8.85223 13.8227C8.46779 13.046 8.34122 12.8056 8.00597 12.4717C7.67065 12.1378 7.43016 12.0124 6.65301 11.6315C5.35512 10.9953 3.97389 10.6017 2.60202 10.1596C1.75554 9.88674 0.585097 9.58016 0.504075 8.50694C0.448461 7.77026 0.975805 7.34129 1.24615 7.154C1.54222 6.94888 1.96054 6.74327 2.38099 6.53376C4.30178 5.57655 7.18785 4.1776 9.45632 3.2493C12.1699 2.13884 14.9611 1.21156 17.7474 0.302305C18.5138 0.0522194 19.4413 -0.269533 20.1053 0.391982C20.7693 1.05349 20.4489 1.98009 20.2 2.74567ZM18.2725 2.22551C17.5798 4.35766 16.4076 7.85619 15.4154 10.2883C14.5255 12.4694 13.1702 15.2774 12.218 17.1942C11.9705 16.4358 11.7076 15.628 11.5063 15.0042C11.2796 14.3019 10.9491 13.5651 10.6401 12.9407C10.2658 12.1844 10.0129 11.6582 9.41433 11.0621C8.81582 10.4662 8.28913 10.2155 7.53192 9.84436C6.9119 9.54046 6.18144 9.21631 5.4844 8.99405C4.8653 8.79664 4.06397 8.53888 3.31036 8.29579C5.22649 7.34145 8.03261 5.98359 10.2127 5.09146C12.6435 4.09674 16.1403 2.92087 18.2725 2.22551Z' fill='white'/%3E%3C/svg%3E%0A");
  width: 20px;
  height: 20px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

[class].btn--send span, button.formsapp-popup-button--send span {
  display: none;
}

[class].btn--slider, button.formsapp-popup-button--slider {
  padding: 11px;
}

[class].btn.link-btn, button.formsapp-popup-button.link-btn {
  cursor: pointer;
  width: fit-content;
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-black);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  height: var(--line-height);
  background-color: var(--lines-color);
  width: 100%;
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (width <= 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

.hero__inner {
  border-radius: calc(var(--radius-main) * 2);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  min-height: 584px;
  display: flex;
  overflow: hidden;
}

.hero__content {
  z-index: 1;
  color: var(--color-white);
  width: 100%;
  max-width: 830px;
  padding: 0 5rem;
}

.hero h1 {
  font-size: clamp(40px, 2rem + 2vi, 56px);
  font-weight: var(--fw-800);
  margin-bottom: 2rem;
  line-height: 1.2;
}

.hero p {
  font-size: 24px;
}

.hero--home {
  padding-top: 5rem;
}

.hero--home p {
  margin-bottom: 4.5rem;
}

.hero--services {
  padding-top: 5rem;
}

.hero--services .hero__inner {
  background-position: 100%;
}

.hero--contact p {
  margin-bottom: 0;
}

.hero--articles {
  padding-top: 5rem;
  padding-bottom: 0;
}

.hero--articles h1 {
  text-transform: capitalize;
  color: var(--color-blue);
  font-weight: var(--fw-800);
  margin-bottom: 2.5rem;
  line-height: 1.2;
}

.hero--articles .hero__inner {
  background-color: var(--color-white);
  min-height: 514px;
  position: relative;
}

.hero--articles .hero__content {
  color: var(--color-black);
  max-width: calc(500px + 7.5rem);
}

.hero--articles .hero__content h2 {
  font-size: 32px;
  font-weight: var(--fw-700);
  color: var(--color-black);
  margin-bottom: 24px;
  line-height: 40px;
}

.hero--articles .hero__content p {
  margin-bottom: 40px;
  font-size: 20px;
}

.hero--portfolio {
  padding: 5rem 0;
}

@media only screen and (width <= 992px) {
  .hero {
    padding-top: 0;
  }

  .hero--services {
    margin: 0;
    padding-bottom: 3.75rem;
  }

  .hero--articles {
    padding-top: 1.875rem;
  }

  .hero--articles .hero__content {
    max-width: 400px;
  }

  .hero__inner {
    min-height: 442px;
  }

  .hero__content {
    padding: 40px 30px;
  }

  .hero--portfolio {
    padding-bottom: 3.75rem;
  }
}

@media only screen and (width <= 768px) {
  .hero--articles .hero__inner {
    min-height: 300px;
  }

  .hero--articles .hero__content {
    max-width: 100%;
  }
}

@media only screen and (width <= 576px) {
  .hero {
    margin: 0 -16px;
  }

  .hero--portfolio .hero__inner {
    background-position: 80%;
  }

  .hero__inner {
    border-radius: 0;
  }

  .hero .btn {
    max-width: none;
  }
}

.services {
  background-color: var(--color-gray-400);
  padding-top: 5rem;
}

.services__grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  display: grid;
}

.services__item {
  border-radius: calc(var(--radius-main) * 2);
  background-color: var(--color-white);
  flex-direction: column;
  height: 100%;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.services__item-inner {
  border-radius: calc(var(--radius-main) * 2);
  background: var(--color-white);
  top: calc(var(--radius-main) * -2);
  padding: 32px;
  position: relative;
}

.services h2 {
  margin-bottom: 2.5rem;
}

.services h3 {
  text-transform: capitalize;
  margin-bottom: 14px;
  font-size: 32px;
}

.services__description {
  background: var(--color-white);
  border-radius: calc(var(--radius-main) * 2);
  margin-bottom: 2.5rem;
  padding: 40px;
}

.services__description-inner {
  max-width: 740px;
}

.services .btn {
  margin: 3.75rem auto 0;
}

@media only screen and (width <= 992px) {
  .services {
    padding: 3.75rem 0;
  }

  .services__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .services__item:last-child {
    grid-column: 1 / span 2;
  }

  .services .btn {
    margin-top: 2.5rem;
  }
}

@media only screen and (width <= 768px) {
  .services__grid {
    grid-template-columns: 1fr;
  }

  .services__item:last-child {
    grid-column: auto;
  }
}

.faq {
  background-color: var(--color-gray-bg);
  padding: 2.5rem 0 5rem;
}

.faq h2 {
  margin-bottom: 40px;
}

.faq__item:not(:last-child) {
  margin-bottom: 32px;
}

.faq details {
  border-radius: calc(var(--radius-main) * 2);
  background-color: var(--color-white);
}

.faq details[open] .faq__question:before {
  rotate: 180deg;
}

.faq__question {
  font-weight: var(--fw-700);
  color: var(--color-blue);
  cursor: pointer;
  padding: 40px 6.25rem 40px 40px;
  font-size: clamp(20px, 1.1017rem + .7486vi, 32px);
  line-height: 1.4;
  position: relative;
}

.faq__question:before {
  content: "";
  border-radius: var(--radius-main);
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L8.29289 9.70711C8.68342 10.0976 9.31658 10.0976 9.70711 9.70711L17.7071 1.70711C18.0976 1.31658 18.0976 0.683418 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L9 7.58579L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893Z' fill='%231B1B1B'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px 10px;
  background-color: var(--color-white);
  width: 48px;
  height: 48px;
  position: absolute;
  top: 40px;
  right: 40px;
  overflow: hidden;
}

.faq__answer {
  padding: 0 200px 40px 40px;
}

@media only screen and (width <= 992px) {
  .faq__answer {
    padding-right: 150px;
  }
}

@media only screen and (width <= 768px) {
  .faq__answer {
    padding-right: 150px;
  }

  .faq br {
    display: none;
  }
}

@media only screen and (width <= 576px) {
  .faq {
    padding: 1.875rem 0 2.5rem;
  }

  .faq__question {
    padding: 30px 5rem 30px 30px;
  }

  .faq__question:before {
    top: 24px;
    right: 30px;
  }

  .faq__answer {
    padding: 8px 1.875rem 30px 30px;
  }
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy {
  color: var(--color-black);
  padding-top: 5rem;
}

.policy__inner {
  background-color: var(--color-gray-bg);
  border-radius: var(--radius-double);
  padding: 40px;
}

.policy__content:not(:last-child) {
  margin-bottom: 48px;
}

.policy h1 {
  font-size: clamp(32px, 1.6176rem + 1.3vi, 56px);
  font-weight: var(--fw-800);
  color: var(--color-blue);
  margin-bottom: 2.5rem;
}

.policy h2 {
  font-weight: var(--fw-700);
  color: var(--color-blue);
  margin-bottom: 24px;
  font-size: 30px;
  line-height: 1.33;
}

.policy ul {
  margin-bottom: 30px;
}

.policy li {
  font-weight: var(--fw-400);
  font-size: 20px;
  line-height: 1.3;
}

.policy li:before {
  content: "✓";
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy p {
  letter-spacing: 1.1px;
  font-size: 20px;
  line-height: 1.17;
}

.policy p:not(:last-child) {
  margin-bottom: 48px;
}

.policy a {
  color: var(--color-blue);
}

.policy a:hover, .policy a:focus {
  text-decoration: underline;
}

@media only screen and (width <= 992px) {
  .policy {
    padding-top: 3.75rem;
  }
}

@media only screen and (width <= 768px) {
  .policy {
    padding-top: 2.5rem;
  }
}

.footer {
  color: var(--color-white);
  background-color: var(--color-blue);
  padding: 3.75rem 0;
}

.footer__inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.footer__nav {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.footer__nav.nav__main {
  margin-bottom: 24px;
}

.footer__nav.nav__main .footer__link {
  font-weight: var(--fw-500);
}

.footer__item {
  padding: 8px 24px;
}

.footer__link {
  font-size: 16px;
}

.footer__copyright {
  text-align: center;
  margin-top: 24px;
}

.footer__copyright p {
  font-size: 16px;
}

@media only screen and (width <= 768px) {
  .footer__nav {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer__item {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.about {
  padding-top: 0;
}

.about__grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  display: grid;
}

.about__item {
  border-radius: calc(var(--radius-main) * 2);
  background-color: var(--color-gray-bg);
  flex-direction: column;
  height: 100%;
  padding: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.about__image {
  width: 110px;
  height: 90px;
  margin-bottom: 1rem;
}

.about__image-expertise {
  width: 90px;
}

.about h3 {
  color: var(--color-black);
  margin-bottom: 1rem;
  font-size: 32px;
  line-height: 44px;
}

.about__description {
  margin-bottom: 2.5rem;
}

.about p {
  color: var(--color-black);
  font-size: 20px;
}

.about .btn {
  margin: 5rem auto 0;
}

@media only screen and (width <= 992px) {
  .about .btn {
    margin-top: 2.5rem;
  }

  .about__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .about__item:last-child {
    grid-column: 1 / span 2;
  }
}

@media only screen and (width <= 768px) {
  .about__grid {
    grid-template-columns: 1fr;
  }

  .about__item {
    padding: 24px;
  }

  .about__item:last-child {
    grid-column: auto;
  }
}

@media only screen and (width <= 576px) {
  .about .btn {
    max-width: none;
  }
}

.reviews {
  max-width: 100%;
  padding-top: 6.25rem;
  overflow: hidden;
}

.reviews__list {
  grid-template-columns: repeat(2, 2fr);
  gap: 60px 80px;
  display: grid;
}

.reviews__item {
  flex-direction: row;
  display: flex;
}

.reviews__author {
  flex-direction: column;
  align-items: center;
  margin-right: 32px;
  display: flex;
}

.reviews__author-image img {
  border-radius: 100%;
  width: 64px;
  height: 64px;
}

.reviews__content {
  flex: 1;
}

.reviews h3 {
  color: var(--color-blue);
  margin-bottom: 12px;
  font-size: 24px;
}

.reviews p {
  margin-bottom: 16px;
}

.reviews svg {
  max-height: 20px;
}

@media only screen and (width <= 992px) {
  .reviews {
    padding-top: 3.75rem;
  }

  .reviews__list {
    grid-template-columns: 1fr;
  }

  .reviews__author {
    flex-basis: 150px;
  }
}

@media only screen and (width <= 576px) {
  .reviews__item {
    flex-direction: column;
  }

  .reviews__author {
    flex-basis: auto;
  }
}

.request {
  background-color: var(--color-gray-400);
  padding-top: 6.25rem;
}

.request h2 {
  margin-bottom: 2.5rem;
}

.request__box {
  border-radius: calc(var(--radius-main) * 2);
  background: url("../images/contact/bg.jpg") 50%;
}

.request__box-header {
  color: var(--color-white);
  border-top-left-radius: calc(var(--radius-main) * 2);
  border-top-right-radius: calc(var(--radius-main) * 2);
  background: #0003;
  padding: 40px;
  line-height: 1.4;
}

.request__box-header h3 {
  color: var(--color-white);
  margin-bottom: 10px;
  font-size: 40px;
}

.request__box-header a {
  font-size: 32px;
  text-decoration: underline;
}

.request__box-footer {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.request__box-footer > div {
  padding: 24px 40px;
}

.request__box-timetable {
  border-bottom-left-radius: calc(var(--radius-main) * 2);
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  display: flex;
}

.request__box-timetable .timetable__item > span {
  font-weight: var(--fw-700);
}

.request__box-info {
  background: #0a0a0ab3;
}

.request__box-info p {
  color: var(--color-white);
  font-size: 16px;
}

.request__form {
  background-color: var(--color-white);
  border-radius: calc(var(--radius-main) * 2);
  margin-top: 3.5rem;
  padding: 40px;
}

.request__form h3 {
  margin-bottom: 24px;
  font-size: 32px;
}

.request__form-header {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.request__form-header > div {
  flex-basis: 50%;
}

.request__form-header > div:last-child {
  margin-left: 16px;
}

.request__form-field {
  margin-bottom: 16px;
  font-size: 16px;
}

.request__form input, .request__form textarea {
  border-radius: var(--radius-main);
  background-color: var(--color-gray-bg);
  width: 100%;
  max-width: 100%;
  padding: 16px;
}

.request__form input {
  padding: 16px;
}

.request__form textarea {
  border: none;
  height: 120px;
}

.request__form .btn {
  text-align: center;
  justify-content: center;
  width: 100%;
  max-width: none;
}

.request__form .btn:after {
  display: none;
}

@media only screen and (width <= 992px) {
  .request {
    padding-top: 3.75rem;
  }

  .request__box-timetable {
    font-size: 16px;
  }

  .request__form {
    margin-top: 2.5rem;
  }

  .request a {
    font-size: 24px;
  }
}

@media only screen and (width <= 768px) {
  .request__box-footer {
    grid-template-columns: 1fr;
  }

  .request__box-timetable {
    border-radius: 0%;
  }

  .request__box-info {
    border-bottom-left-radius: calc(var(--radius-main) * 2);
    border-bottom-right-radius: calc(var(--radius-main) * 2);
  }
}

@media only screen and (width <= 576px) {
  .request__box-header h3 {
    font-size: 28px;
  }

  .request a {
    font-size: 20px;
  }

  .request textarea {
    height: 120px;
    margin-bottom: 20px;
    overflow: hidden;
  }
}

.articles {
  background-color: var(--color-gray-bg);
  padding-top: 6.25rem;
}

.articles__main {
  margin-top: 2.5rem;
}

.articles__main .hero__inner {
  background-color: var(--color-white);
  justify-content: flex-start;
  min-height: 514px;
  position: relative;
}

.articles__main .hero__content {
  color: var(--color-black);
  box-sizing: content-box;
  max-width: 500px;
  padding-top: 80px;
  padding-left: 80px;
}

.articles__main .hero__content h3 {
  margin-bottom: 1.5rem;
  font-size: 32px;
  line-height: 1.2;
}

.articles__main .hero__description {
  margin-bottom: 2.5rem;
}

.articles__grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 40px;
  display: grid;
}

.articles__item {
  border-radius: var(--radius-main);
  position: relative;
  overflow: hidden;
}

.articles__item img {
  width: 100%;
}

.articles__item a {
  z-index: 1;
  background: var(--gradient-dark);
  position: absolute;
  inset: 0;
}

.articles__item h3 {
  text-transform: none;
  color: var(--color-white);
  max-width: 400px;
  padding: 32px 0 32px 32px;
  font-size: 32px;
  line-height: 40px;
  position: absolute;
  bottom: 0;
}

.articles__bg {
  -webkit-clip-path: polygon(60% 0, 100% 0, 100% 100%, 52% 100%);
  clip-path: polygon(60% 0, 100% 0, 100% 100%, 52% 100%);
  background-position: 100% 0;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
}

.articles .btn {
  width: fit-content;
}

.articles .btn.link-btn {
  margin: 5rem auto 0;
}

@media only screen and (width <= 992px) {
  .articles {
    padding-top: 3.75rem;
  }

  .articles .hero__inner {
    background-size: auto;
    min-height: 400px;
  }

  .articles .hero__content {
    max-width: 350px;
    padding: 40px;
  }

  .articles .btn.link-btn {
    margin-top: 2.5rem;
  }
}

@media only screen and (width <= 768px) {
  .articles__bg {
    display: none;
  }

  .articles .hero__inner {
    background-size: auto;
    min-height: auto;
  }

  .articles .hero__content {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (width <= 576px) {
  .articles__grid {
    grid-template-columns: 1fr;
  }
}

.commitment h2 {
  margin-bottom: 40px;
}

.commitment__grid {
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  display: grid;
}

.commitment__item {
  background-color: var(--color-gray-bg);
  border-radius: calc(var(--radius-main) * 2);
  padding: 40px;
}

.commitment__item img {
  margin-bottom: 1rem;
}

.commitment__item h3 {
  text-transform: none;
  margin-bottom: 1rem;
  font-size: 32px;
  line-height: 1.4;
}

@media only screen and (width <= 768px) {
  .commitment__grid {
    grid-template-columns: 1fr;
  }

  .commitment br {
    display: none;
  }
}

.service {
  padding-top: 0;
}

.service__block {
  background-color: var(--color-gray-bg);
  border-radius: 32px;
  margin-top: 20px;
  padding: 40px;
}

.service__block-header {
  margin-bottom: 32px;
}

.service__block-header h3 {
  margin-bottom: 16px;
}

.service__block-first {
  background-image: url("../images/services/first_bg.png");
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.service__block-first p {
  max-width: 740px;
}

.service__grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  display: grid;
}

.service__item {
  background-color: var(--color-white);
  border-radius: var(--radius-double);
  padding: 32px;
}

.service__item-title {
  font-size: 24px;
  font-weight: var(--fw-700);
  color: var(--color-blue);
  margin-bottom: 16px;
}

.service__item-three {
  grid-column: 1 / span 2;
}

.service__image {
  margin-top: 20px;
}

.service__image img {
  border-radius: var(--radius-double);
  width: 100%;
  height: 100%;
}

@media only screen and (width <= 992px) {
  .service__block-first {
    background-image: none;
  }

  .service__block-first p {
    max-width: none;
  }
}

@media only screen and (width <= 768px) {
  .service__grid {
    grid-template-columns: 1fr;
  }

  .service__item-three {
    grid-column: auto;
  }
}

@media only screen and (width <= 576px) {
  .service__block {
    padding: 30px;
  }
}

.team {
  padding-top: 0;
  padding-bottom: 6.25rem;
  overflow: hidden;
}

.team h2 {
  margin: 0 auto 2.5rem;
}

.team__list {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.team__item {
  flex-direction: column;
  flex-basis: 216px;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  margin-right: 40px;
  display: flex;
  overflow: hidden;
}

.team__item:last-child {
  margin-right: 0;
}

.team__image {
  margin-bottom: 20px;
}

.team__image img {
  border-radius: 50%;
  max-width: 150px;
  max-height: 150px;
}

.team__name h3 {
  margin: 0 auto;
  font-size: 32px;
}

.team__name span {
  text-align: center;
  display: block;
}

@media only screen and (width <= 992px) {
  .team {
    padding-bottom: 3.75rem;
  }

  .team__item img {
    max-width: 120px;
  }
}

@media only screen and (width <= 768px) {
  .team__list {
    flex-wrap: wrap;
  }

  .team__item {
    flex-basis: 40%;
    margin-top: 20px;
    margin-right: 0;
  }

  .team__item:nth-child(3), .team__item:nth-child(4) {
    margin-top: 40px;
  }

  .team__image {
    margin-bottom: 10px;
  }
}

@media only screen and (width <= 576px) {
  .team__item {
    flex-basis: 100%;
    margin-top: 40px;
  }

  .team__item:first-child {
    margin-top: 20px;
  }
}

.mission {
  background-color: var(--color-gray-bg);
  padding: 6.25rem 0 5rem;
}

.mission h2 {
  margin-bottom: 40px;
}

.mission__list {
  grid-template-columns: repeat(3, 2fr);
  gap: 20px;
  display: grid;
}

.mission__item {
  border-radius: calc(var(--radius-main) * 2);
  background-color: var(--color-white);
  padding: 1.625rem;
}

.mission__item h3 {
  margin-bottom: 12px;
  font-size: 32px;
}

@media only screen and (width <= 992px) {
  .mission {
    padding: 3.75rem 0;
  }

  .mission__list {
    grid-template-columns: repeat(2, 2fr);
    gap: 15px;
  }
}

@media only screen and (width <= 576px) {
  .mission__list {
    grid-template-columns: 1fr;
  }
}

.quality {
  background-color: var(--color-gray-bg);
  padding-top: 0;
}

.quality h2 {
  margin-bottom: 20px;
}

.quality__description {
  margin-bottom: 40px;
}

.quality__grid {
  grid-template-columns: repeat(2, 3fr);
  gap: 20px;
  display: grid;
}

.quality__item {
  border-radius: calc(var(--radius-main) * 2);
  background-color: var(--color-white);
  padding: 1.625rem;
}

.quality__item h3 {
  margin-bottom: 12px;
  font-size: 32px;
}

@media only screen and (width <= 768px) {
  .quality__item {
    padding: 24px;
  }
}

@media only screen and (width <= 576px) {
  .quality__grid {
    grid-template-columns: 1fr;
  }
}

.approach {
  background: url("../images/about/approach.jpg") top / cover no-repeat;
  padding-top: 2.5rem;
}

.approach__header, .approach__item {
  background-color: var(--color-white);
  border-radius: calc(var(--radius-main) * 2);
}

.approach__header {
  padding: 40px;
}

.approach__header h2, .approach__header h3 {
  margin-bottom: 16px;
}

.approach__header h3 {
  font-size: 32px;
  line-height: 44px;
}

.approach__grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
  display: grid;
}

.approach__first {
  grid-template-columns: repeat(4, 1fr);
}

.approach__item {
  padding: 24px;
}

.approach__item-title {
  font-size: 24px;
  font-weight: var(--fw-700);
  color: var(--color-blue);
  margin-bottom: 12px;
  line-height: 33px;
}

@media only screen and (width <= 992px) {
  .approach__first {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (width <= 768px) {
  .approach__item {
    padding: 24px;
  }
}

@media only screen and (width <= 576px) {
  .approach__grid {
    grid-template-columns: 1fr;
  }
}

.who {
  padding-top: 0;
  padding-bottom: 5rem;
}

.who__sides {
  grid-template-columns: 1fr 600px;
  gap: 80px;
  margin-bottom: 1rem;
  display: grid;
}

.who__sides-left h2, .who__sides-left p {
  margin-bottom: 1.5rem;
}

.who__sides-right img {
  border-radius: calc(var(--radius-main) * 2);
}

@media only screen and (width <= 992px) {
  .who {
    padding-bottom: 3.75rem;
  }

  .who__sides {
    grid-template-columns: 1fr 1fr;
  }

  .who__sides-right {
    justify-content: center;
    display: flex;
  }
}

@media only screen and (width <= 768px) {
  .who__sides {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .who__text {
    margin-top: 60px;
  }
}

@media only screen and (width <= 576px) {
  .who__item {
    padding: 16px;
  }

  .who__image {
    width: 100%;
  }
}

.pages {
  padding-top: 0;
}

.pages__grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 40px 20px;
  margin-top: 40px;
  display: grid;
}

.pages__item {
  border-radius: var(--radius-double);
  position: relative;
  overflow: hidden;
}

.pages__item:last-child img {
  aspect-ratio: 16 / 9;
}

.pages__item img {
  aspect-ratio: 1;
  width: 100%;
  max-height: 636px;
}

.pages__item a {
  z-index: 1;
  background: var(--gradient-dark);
  position: absolute;
  inset: 0;
}

.pages__item h3 {
  text-transform: none;
  color: var(--color-white);
  padding: 0 100px 32px 32px;
  font-size: 32px;
  line-height: 1.4;
  position: absolute;
  bottom: 0;
}

.pages__item-full {
  grid-column: 1 / span 2;
}

.pages__item-full h3 {
  max-width: 882px;
  font-size: clamp(40px, 1.8451rem + 1.0458vi, 56px);
}

@media only screen and (width <= 992px) {
  .pages {
    padding-bottom: 3.75rem;
  }
}

@media only screen and (width <= 768px) {
  .pages__item h3 {
    padding: 15px 32px 15px 15px;
    font-size: 27px;
  }
}

@media only screen and (width <= 576px) {
  .pages__title {
    padding-left: 30px;
  }

  .pages__grid {
    grid-template-columns: 1fr;
  }

  .pages__item h3 {
    padding-right: 32px;
    font-size: 24px;
  }

  .pages__item-full {
    grid-column: auto;
  }
}

.article {
  padding-top: 5rem;
}

.article__title {
  text-transform: capitalize;
  color: var(--color-blue);
  font-size: clamp(2rem, 2rem + 1vi, 56px);
  font-weight: var(--fw-800);
  line-height: 1.2;
}

.article__inner {
  background-color: var(--color-gray-bg);
  border-radius: var(--radius-double);
  margin-top: 40px;
  padding: 40px;
}

.article__inner > :last-child {
  margin-bottom: 0;
}

.article__inner > p {
  margin-bottom: 40px;
}

.article__inner > p.ol {
  color: var(--color-blue);
  font-size: 32px;
  font-weight: var(--fw-700);
  margin-bottom: 12px;
}

.article__inner > p.title__black {
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 800;
}

.article__inner img {
  border-radius: var(--radius-double);
  margin-bottom: 40px;
}

.article__inner .list-disc {
  margin-bottom: 40px;
}

.article__inner .list-disc li {
  list-style-type: disc;
}

.article__list {
  grid-template-columns: repeat(2, 3fr);
  gap: 20px;
  margin-bottom: 40px;
  display: grid;
}

.article__item {
  border-radius: calc(var(--radius-main) * 2);
  background-color: var(--color-white);
  padding: 1.625rem;
}

.article__item h3 {
  margin-bottom: 12px;
  font-size: 32px;
}

.article__item {
  list-style: none;
}

.article__item:last-child {
  grid-column: 1 / span 2;
}

@media only screen and (width <= 992px) {
  .article {
    padding-top: 3.75rem;
  }
}

@media only screen and (width <= 576px) {
  .article {
    padding: 2.5rem 0;
  }

  .article__title {
    font-size: 40px;
  }

  .article__inner {
    padding: 30px;
  }

  .article__inner p.title__black {
    font-size: 24px;
  }

  .article__list {
    grid-template-columns: 1fr;
  }

  .article__item:last-child {
    grid-column: auto;
  }
}

.catalog {
  padding-top: 0;
}

.catalog__header h2 {
  margin-bottom: 40px;
}

.catalog__item {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 120px;
  margin-top: 100px;
  display: grid;
}

.catalog__item:nth-child(2n) .catalog__content {
  grid-area: 1 / 2 / 2 / 3;
}

.catalog__item:nth-child(2n) .catalog__image {
  grid-column: 1 / 2;
}

.catalog__content h2 {
  margin-bottom: 24px;
}

.catalog__image img:last-child {
  margin-top: 16px;
}

@media only screen and (width <= 992px) {
  .catalog__item {
    gap: 80px;
    margin-top: 80px;
  }
}

@media only screen and (width <= 768px) {
  .catalog__item {
    grid-template-columns: 1fr;
    gap: 40px;
    margin-top: 60px;
  }

  .catalog__item:nth-child(2n) .catalog__content {
    grid-area: auto;
  }

  .catalog__image {
    margin: 0 auto;
  }
}
/*# sourceMappingURL=main.css.map */
