@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.policy {
  padding-top: rem(80);
  color: var(--color-black);

  &__inner {
    background-color: var(--color-gray-bg);
    padding: 40px;
    border-radius: var(--radius-double);
  }

  &__content {
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  h1 {
    margin-bottom: rem(40);
    font-size: clamp(32px, 1.6176rem + 1.3vi, 56px);
    font-weight: var(--fw-800);
    color: var(--color-blue);
  }

  h2 {
    margin-bottom: 24px;
    font-weight: var(--fw-700);
    font-size: 30px;
    line-height: 1.33;
    color: var(--color-blue);
  }

  ul {
    margin-bottom: 30px;
  }

  li {
    font-weight: var(--fw-400);
    font-size: 20px;
    line-height: 1.3;

    &::before {
      content: "✓";
    }

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  p {
    font-size: 20px;
    line-height: 1.17;
    letter-spacing: 1.1px;

    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  a {
    color: var(--color-blue);

    &:hover,
    &:focus {
      text-decoration: underline;

    }
  }

  @include media(tablet) {
    padding-top: rem(60);
  }

  @include media(mobile-l) {
    padding-top: rem(40);
  }
}