@use "../utils/functions/" as*;
@use "../utils/mixins/" as*;

.faq {
  $root: &;

  background-color: var(--color-gray-bg);
  padding: rem(40) 0 rem(80);

  h2 {
    margin-bottom: 40px;
  }

  &__item {

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  details {
    border-radius: calc(var(--radius-main) * 2);
    background-color: var(--color-white);

    &[open] {
      #{$root}__question::before {
        rotate: 180deg;
      }
    }
  }

  &__question {
    position: relative;
    padding: 40px rem(100) 40px 40px;
    font-weight: var(--fw-700);
    font-size: clamp(20px, 1.1017rem + 0.7486vi, 32px);
    line-height: 1.4;
    color: var(--color-blue);
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      right: 40px;
      top: 40px;
      overflow: hidden;
      border-radius: var(--radius-main);
      width: 48px;
      height: 48px;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L8.29289 9.70711C8.68342 10.0976 9.31658 10.0976 9.70711 9.70711L17.7071 1.70711C18.0976 1.31658 18.0976 0.683418 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L9 7.58579L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893Z' fill='%231B1B1B'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: 18px 10px;
      background-repeat: no-repeat;
      background-color: var(--color-white);
    }
  }

  &__answer {
    padding: 0 200px 40px 40px;
  }

  @include media(tablet) {
    &__answer {
      padding-right: 150px;
    }
  }

  @include media(mobile-l) {
    &__answer {
      padding-right: 150px;
    }

    br {
      display: none;
    }
  }
  
  @include media(mobile-m) {
    padding: rem(30) 0 rem(40);

    &__question {
      padding: 30px rem(80) 30px 30px;

      &::before {
        right: 30px;
        top: 24px;
      }
    }

    &__answer {
      padding: 8px rem(30) 30px 30px;
    }
  }
}