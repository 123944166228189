@use "../../utils/functions/" as*;
@use "../../utils/mixins/" as*;

.who {
  $root: &;
  padding-top: 0;
  padding-bottom: rem(80);

  &__sides {
    display: grid;
    grid-template-columns: 1fr 600px;
    gap: 80px;
    margin-bottom: rem(16);

    &-left {
      h2,
      p {
        margin-bottom: rem(24);
      }
    }

    &-right {
      img {
        border-radius: calc(var(--radius-main) * 2);
      }
    }
  }

  @include media(tablet) {
    padding-bottom: rem(60);
    &__sides {
      grid-template-columns: 1fr 1fr;

      &-right {
        display: flex;
        justify-content: center;
      }
    }
  }

  @include media(mobile-l) {
    &__sides {
      grid-template-columns: 1fr;
      gap: 20px;
    }

    &__text {
      margin-top: 60px;
    }
  }

  @include media(mobile-m) {
    &__item {
      padding: 16px;
    }

    &__image {
      width: 100%;
    }
  }
}