@use "../../utils/functions/" as*;
@use "../../utils/mixins/" as*;

.service {
  $root: &;
  padding-top: 0;

  &__block {
    margin-top: 20px;
    border-radius: 32px;
    background-color: var(--color-gray-bg);
    padding: 40px;

    &-header {
      margin-bottom: 32px;

      h3 {
        margin-bottom: 16px;
      }
    }

    &-first {
      background-image: url('../images/services/first_bg.png');
      background-repeat: no-repeat;
      background-position-x: right;
      background-size: contain;

      p {
        max-width: 740px;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }

  &__item {
    padding: 32px;
    background-color: var(--color-white);
    border-radius: var(--radius-double);

    &-title {
      font-size: 24px;
      font-weight: var(--fw-700);
      color: var(--color-blue);
      margin-bottom: 16px;
    }

    &-three {
      grid-column: 1 / span 2;
    }
  }

  &__image {
    margin-top: 20px;

    img {
      border-radius: var(--radius-double);
      width: 100%;
      height: 100%;
    }
  }

  @include media(tablet) {
    &__block {
      &-first {
        background-image: none;
        p {
          max-width: none;
        }
      }
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &__item {
      &-three {
        grid-column: auto;
      }
    }
  }

  @include media(mobile-m) {
    &__block {
      padding: 30px;
    }
  }
}