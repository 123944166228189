@use "../../utils/functions/" as*;
@use "../../utils/mixins/" as*;

.commitment {
    
    h2 {
        margin-bottom: 40px;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    &__item {
        background-color: var(--color-gray-bg);
        padding: 40px;
        border-radius: calc(var(--radius-main) * 2);

        img {
            margin-bottom: rem(16);
        }

        h3 {
            margin-bottom: rem(16);
            font-size: 32px;
            line-height: 1.4;
            text-transform: none;
        }
    }

    @include media(mobile-l) {
        &__grid {
            grid-template-columns: 1fr;
        }
        br {
            display: none;
          }
    }

}